<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <b-button class="mb-4" variant="primary" @click="newWallet">
            {{ $t("ROUTES.NEW_WALLET") }}
          </b-button>

          <div class="d-flex align-items-end">
            <b-button
              @click="onClickShowUnassignModal"
              class="mb-4 mr-2"
              variant="danger"
              :disabled="
                !hasAnySelectedItems || (branchesLoading && !isAssigning)
              "
            >
              <b-spinner
                small
                type="grow"
                v-if="branchesLoading && !isAssigning"
              ></b-spinner>
              <span v-if="hasAnySelectedItems" class="mr-1">
                {{ selectedRows.length }}
                {{ $t("ROUTES.WALLET") }}
              </span>
              {{ $t("wallets.unassign_to_branch") }}
            </b-button>
            <b-button
              @click="onClickShowAssignModal"
              class="mb-4"
              variant="success"
              :disabled="
                !hasAnySelectedItems || (branchesLoading && isAssigning)
              "
            >
              <b-spinner
                small
                type="grow"
                v-if="branchesLoading && isAssigning"
              ></b-spinner>
              <span v-if="hasAnySelectedItems" class="mr-1">
                {{ selectedRows.length }}
                {{ $t("ROUTES.WALLET") }}
              </span>
              {{ $t("wallets.assign_to_branch") }}
            </b-button>
          </div>
        </div>

        <b-table
          ref="walletTable"
          id="wallet-table"
          primary-key="id"
          :items="wallets"
          :fields="fields"
          selectable
          select-mode="multi"
          :busy.sync="isLoading"
          @row-selected="onRowSelected"
          @row-clicked="onRowClicked"
          @refreshed="onRefreshed"
          sort-icon-left
          tbody-class="body-text-font-size"
        >
          <template #head(checked)="data">
            <div class="d-inline-flex">
              <b-form-checkbox
                id="select-all-checkbox"
                name="select-all-checkbox"
                v-model="allRowsChecked"
                value="true"
                unchecked-value="false"
                @change="onCheckAllChange"
                :indeterminate="indeterminateCheck"
              />
            </div>
          </template>
          <template #cell(checked)="data">
            <div class="d-inline-flex" v-if="data.item.isActive">
              <b-form-checkbox
                :id="`select-item-checkbox-${data.index}`"
                :name="`select-item-checkbox-${data.index}`"
                v-model="data.item.checked"
                unchecked-value="false"
                @change="onRowCheck(data)"
              />
            </div>
          </template>

          <template #cell(currencyCode)="data">
            <span class="d-inline-flex align-items-center">
              <img
                style="width: 20px; height: 20px;"
                :src="data.item.currencyIcon"
              />
              <span class="ml-2">{{ data.item.currencyCode }}</span>
            </span>
          </template>

          <template #cell(isActive)="data">
            <b-icon
              icon="check"
              font-scale="2.5"
              class="common-icon-color"
              variant="success"
              v-if="data.value"
            ></b-icon>
            <b-icon
              icon="x"
              v-else
              font-scale="2.5"
              class="common-icon-color"
            ></b-icon>
          </template>

          <!-- <template #cell(buttons)="row">
            <button
              class="list-button"
              @click.stop.prevent="fetchAffiliatedBranches(row)"
            >
              <b-icon
                :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
                variant="success"
                font-scale="1.5"
              ></b-icon>
            </button>
          </template>
          <template #row-details="row">
            <b-card>
              affiliated branches
            </b-card>
          </template> -->

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      @ok="onOkClickConfirm"
      id="assign-modal"
      :cancel-title="$t('FORM.CANCEL')"
      :ok-title="$t('FORM.OK')"
      :title="
        isAssigning
          ? $t('FORM.ASSIGNMENT_CONFIRMATION')
          : $t('FORM.UNASSIGNMENT_CONFIRMATION')
      "
      no-close-on-esc
      no-close-on-backdrop
      body-class="body-text-font-size"
    >
      <b-container fluid>
        <div class="b-form-group mb-5">
          <label for="input-company" class="col-form-label font-weight-bold">
            {{ $t("wallets.selectedWallets") }}
          </label>
          <div class="">
            <span
              class="d-inline-flex align-items-center"
              v-for="(wallet, walletKey) in selectedRows"
              :key="`wallet-${walletKey}`"
            >
              <img
                style="width: 20px; height: 20px;"
                :src="wallet.currencyIcon"
              />
              <span>{{ wallet.currencyCode }}</span>
              <span
                :class="{ 'mr-2': walletKey !== selectedRows.length - 1 }"
                v-if="walletKey !== selectedRows.length - 1"
                >,
              </span>
            </span>
          </div>
        </div>
        <div class="w-100 bg-secondary my-1" style="height: 2px;"></div>
        <div class="b-form-group mb-5">
          <label for="input-company" class="col-form-label font-weight-bold">
            {{ $t("ROUTES.BRANCHES") }} ({{ $t("COMMON.can_select_multi") }})
          </label>
          <multiselect
            v-model="selectedBranches"
            deselect-label=""
            select-label=""
            selected-label=""
            selectGroupLabel=""
            deselectGroupLabel=""
            track-by="title"
            label="title"
            groupValues="options"
            :groupLabel="
              areAllBranchesSelected ? 'unselect_all_label' : 'select_all_label'
            "
            :group-select="true"
            :placeholder="$t('ECOMMERCE.COMMON.SEARCH')"
            :options="branchOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            open-direction="bottom"
            :loading="branchesLoading"
            :disabled="branchesLoading"
            :limit="3"
            :limit-text="limitText"
          >
            <!-- <template slot="option" slot-scope="{ option }">{{
              option.title
            }}</template>
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length"
                v-show="!isOpen"
                >{{
                  $t("FORM.MUCH_OPTIONS_SELECTED", { value: values.length })
                }}</span
              ></template
            > -->
            <template slot="noOptions">{{
              $t("COMMON.NO_DATA_AVAILABLE")
            }}</template>
            <template slot="noResult">{{
              $t("COMMON.NO_DATA_AVAILABLE")
            }}</template>
          </multiselect>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions, mapState } from "vuex";
import Api from "@/services/api";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      allRowsChecked: false,
      selectedRows: [],
      listData: [],
      branchesLoading: false,
      branchOptions: [
        {
          select_all_label: this.$t("wallets.select_all"),
          unselect_all_label: this.$t("wallets.unselect_all"),
          options: []
        }
      ],
      fields: [
        {
          key: "checked",
          class: "text-center"
        },
        {
          key: "id",
          label: "ID",
          sortable: true
        },
        {
          key: "currencyCode",
          label: this.$t("wallets.currencyCode"),
          sortable: true
        },
        {
          key: "currencyName",
          label: this.$t("wallets.currencyName"),
          sortable: true
        },
        {
          key: "isActive",
          label: this.$t("ECOMMERCE.COMMON.ACTIVE")
        },
        {
          key: "buttons",
          label: "",
          class: "text-center"
        }
      ],
      selectedBranches: [],
      assignType: "assign"
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.wallets.isLoading,
      wallets: state =>
        state.wallets.wallets.map(val =>
          !val?.isActive ? { ...val, _rowVariant: "disabled-row-variant" } : val
        )
    }),
    activeWallets() {
      return this.wallets.filter(val => val.isActive);
    },
    itemsCount() {
      return this.wallets.length;
    },
    hasAnySelectedItems() {
      return this.selectedRows.length > 0;
    },
    indeterminateCheck() {
      const selectedRowsCount = this.selectedRows.length;
      return selectedRowsCount > 0 && selectedRowsCount < this.itemsCount;
    },
    walletTable() {
      return this.$refs.walletTable;
    },
    unselectedWallets() {
      const selectedWalletsMap = this.selectedRows.map(val => val.id);
      return this.wallets.filter(val => !selectedWalletsMap.includes(val.id));
    },
    canAssign() {
      return this.selectedBranches.length > 0;
    },
    isAssigning() {
      return this.assignType === "assign";
    },
    areAllBranchesSelected() {
      return (
        this.selectedBranches.length > 0 &&
        this.branchOptions[0]?.options.length > 0 &&
        this.branchOptions[0]?.options.length === this.selectedBranches.length
      );
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.WALLETS") }
    ]);
  },
  methods: {
    ...mapActions({
      fetchWallets: "wallets/GET_WALLETS",
      assignWalletsToBranches: "wallets/assignWalletsToBranches"
    }),
    newWallet() {
      this.$router.push({ name: "new-wallet" });
    },
    async onModalShow() {
      this.branchesLoading = true;
      try {
        const res = await Api.get("companies", "?role.id=4");
        this.branchOptions[0] = { ...this.branchOptions[0], options: res.data };
        this.$bvModal.show("assign-modal");
      } catch (error) {
        throw error;
      } finally {
        this.branchesLoading = false;
      }
    },
    onOkClickConfirm(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (!this.canAssign) {
        this.$root.$bvToast.toast(
          this.$t("wallets.please_select_branches_first"),
          {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          }
        );
        return;
      }
      const self = this;
      Swal.fire({
        icon: "warning",
        title: this.$t("COMMON.INFORM"),
        html: this.isAssigning
          ? this.$t("wallets.assignConfirmWarning")
          : this.$t("wallets.unassignConfirmWarning"),
        confirmButtonText: this.$t("FORM.OK"),
        allowOutsideClick: false,
        cancelButtonText: this.$t("FORM.CANCEL"),
        showCancelButton: true
      }).then(res => {
        if (res.isConfirmed) {
          self.onOkClick();
        }
      });
    },
    async onOkClick() {
      // assign to branches
      const currencyCodes = this.selectedRows.map(wallet => {
        return `${wallet.currencyCode}`.toLowerCase();
      });
      const _branches = this.selectedBranches.map(val => val.id);
      try {
        await this.assignWalletsToBranches({
          companyId: _branches,
          currencyCode: currencyCodes,
          isActive: this.isAssigning
        });
        this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
        this.$bvModal.hide("assign-modal");
        this.selectedBranches = [];
        this.onCheckAllChange(false);
        this.walletTable.refresh();
      } catch (error) {
        const message = error.response.data?.message || this.$t("FORM.ERROR");
        this.$root.$bvToast.toast(message, {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    onRowCheck(data) {
      if (!data.item?.isActive) return;
      const checked = data.item.checked;
      const checkAction = checked == true ? data.selectRow : data.unselectRow;
      checkAction();
    },
    onRowClicked(item) {
      if (!item?.isActive) return;
      let isChecked = true;
      if (this.selectedRows.some(val => val.id === item.id)) {
        isChecked = false;
      }
      this.wallets.find(val => val.id === item.id).checked = isChecked;
    },
    onRowSelected(items) {
      // this.selectedRows = items.filter(val => val.isActive);
      const selectedItems = [];
      items.forEach(item => {
        if (!item.isActive) {
          const findIndex = this.wallets.findIndex(
            val => `${val.id}` === `${item.id}`
          );
          item.checked = false;
          this.walletTable.unselectRow(findIndex);
        } else {
          selectedItems.push(item);
        }
      });
      this.selectedRows = selectedItems;
    },
    onRefreshed() {
      this.resetProperties();
      this.listData.forEach(data => (data["checked"] = false));
    },
    resetProperties() {
      this.allRowsChecked = false;
      this.selectedRows = [];
      this.branchOptions[0] = { ...this.branchOptions[0], options: [] };
    },
    onCheckAllChange(checked) {
      const isChecked = checked == true || checked == "true";

      const checkAction = isChecked
        ? this.walletTable.selectAllRows
        : this.walletTable.clearSelected;
      checkAction();
      this.activeWallets.forEach(val => {
        val["checked"] = checked;
      });
    },
    /**
     * @TODO
     * fetch affiliated branches on selected currency
     * api/companies/{companyID}/company-wallets
     */
    fetchAffiliatedBranches(row) {
      row.toggleDetails();
    },
    onClickShowAssignModal() {
      this.assignType = "assign";
      this.onModalShow();
    },
    onClickShowUnassignModal() {
      this.assignType = "unassign";
      this.onModalShow();
    },
    limitText(count) {
      return this.$t("COMMON.multi_select_limit_text", { count });
    }
  },
  async created() {
    this.fetchWallets();
  }
};
</script>

<style lang="scss">
.edit-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.body-text-font-size {
  font-size: 1.15rem !important;
}
.table-disabled-row-variant {
  pointer-events: none !important;
  background: #f8f9fa !important;
}
</style>
<style lang="scss" scoped>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1.15rem !important;
}
</style>
